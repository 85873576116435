<template>
  <!-- 页面主体区域 -->
  <div>
    <main>
      <!-- 背景1 -->
      <div class="bg1">
        <img v-lazy="require('./imgs/bg1.webp')" alt="" class="bg11" />
        <img src="" alt="" />
        <span class="gjx">智慧工具箱</span>
        <span class="hyzy">整合多行业资源，为文旅行业赋能</span>
        <el-button @click="showdlg" round class="ljgd" style="border-style:none"
          >加入我们</el-button
        >
      </div>
      <!-- 背景1 -->
      <!-- 背景2 -->
      <div class="bg2">
        <span class="ykfw">游客服务</span>
        <div class="icon1">
          <img src="./imgs/icon1.png" alt="" class="image1" />
          <span class="jqmp">景区名片</span>
          <span class="fh1"
            >景区内景点的图文信息展示，包括景点名称、位置、图片、视频、介绍等，游客可对其进行收藏、转发和分享</span
          >
        </div>
        <div class="icon2">
          <img src="./imgs/icon2.png" alt="" class="image2" />
          <span class="zndh">智能导览</span>
          <span class="fh2"
            >在常规地图上展示景点、卫生间、停车场、出入口等类型的POI。</span
          >
        </div>
        <div class="icon3">
          <img src="./imgs/icon3.png" alt="" class="image3" />
          <span class="tqzs">景区天气</span>
          <span class="fh3"
            >展示详细的天气情况，以区域/区县为颗粒度展示实况、最近天气预报、空气质量、生活指数等。</span
          >
        </div>
        <div class="icon4">
          <img src="./imgs/icon4.png" alt="" class="image4" />
          <span class="cyfw">活动日历</span>
          <span class="fh4"
            >展示景区的活动预告和活动介绍当地节日节庆、特色活动、话剧演出、体育赛事、民族节日等活动推荐。</span
          >
        </div>
        <div class="icon5">
          <img src="./imgs/icon5.png" alt="" class="image5" />
          <span class="hdbm">游玩攻略</span>
          <span class="fh5"
            >展示景区游玩的官方攻略、游玩指南。增强产品体验感与内容丰富性，为游客提供实用指南。游客可以查看、收藏、转发分享给好友</span
          >
        </div>
        <div class="icon6">
          <img src="./imgs/icon6.png" alt="" class="image6" />
          <span class="zsfw">新闻资讯</span>
          <span class="fh6"
            >支持在小程序内发布旅游最新政策公告、景区新闻。
            支持推送图文内容，增强文旅机构信息发布能力。游客可以查看、收藏、转发分享给好友。</span
          >
        </div>
        <div class="icon7">
          <img src="./imgs/icon8.png" alt="" class="image7" />
          <span class="mzb">分时预约</span>
          <span class="fh7"
            >填写个人信息，包括姓名、手机号、参观成员信息等，选择日期、时间进行景区、场馆预约</span
          >
        </div>
        <div class="icon8">
          <img src="./imgs/icon6.png" alt="" class="image8" />
          <span class="fsyy">活动报名</span>
          <span class="fh8"
            >填写个人信息，包括姓名、手机号、参观成员信息等，选择日期、时间进行活动报名</span
          >
        </div>
        <div class="icon9">
          <img src="./imgs/icon9.png" alt="" class="image9" />
          <span class="ds">电商</span>
          <span class="fh9">集成微信小商店，C端店铺装饰，快速开店</span>
        </div>
        <div class="icon10">
          <img src="./imgs/icon10.png" alt="" class="image10" />
          <span class="pw">景区商圈</span>
          <span class="fh10"
            >美食/餐厅推荐、民宿/酒店推荐、购物场所推荐、休闲玩乐推荐</span
          >
        </div>
        <div class="icon11">
          <img src="./imgs/icon11.png" alt="" class="image11" />
          <span class="zbsc">AI 智能客服</span>
          <span class="fh11"
            >24小时在线客服，常识问答，导航回答,
            专属知识互动，景区个性化语料需要专门训练</span
          >
        </div>
        <div class="icon12">
          <img src="./imgs/icon12.png" alt="" class="image12" />
          <span class="znsbhc">AI 识别花草</span>
          <span class="fh12">拍照识别花草，了解花草名称、养护等信息</span>
        </div>
        <div class="icon13">
          <img src="./imgs/icon13.png" alt="" class="image13" />
          <span class="znfy">景区明信片</span>
          <span class="fh13">为游客制作景区明信片，一键分享朋友圈</span>
        </div>
        <div class="icon14">
          <img src="./imgs/icon14.png" alt="" class="image14" />
          <span class="tsjpj">个人中心</span>
          <span class="fh14"
            >包含用户信息展示、用户收藏展示、产品版权信息（客户logo露出）</span
          >
        </div>
        <div class="icon15">
          <img src="./imgs/icon19.png" alt="" class="image15" />
          <span class="jkm">用户评论</span>
          <span class="fh15">对景点、酒店、餐饮、购物进行评论、互评等</span>
        </div>
        <div class="icon16">
          <img src="./imgs/icon15.png" alt="" class="image16" />
          <span class="swdt">用户评星</span>
          <span class="fh16"
            >对景区三个维度进行评星，包括景色、趣味、性价比</span
          >
        </div>
        <div class="icon17">
          <img src="./imgs/icon18.png" alt="" class="image17" />
          <span class="gdfw">更多服务扩展中</span>
          <span class="fh17">敬请期待</span>
        </div>
      </div>
      <!-- 对话框 -->
      <div class="dilog" v-show="showdia">
        <div class="close" @click="closedel"></div>
        <div class="imgtop"></div>
        <div class="imgleft"></div>
        <div class="textone">加入我们</div>
        <div class="texttwo">CONTACT US</div>
        <div class="callwe">
          <img src="./imgs/userwe.png" class="users" title="联系人" />

          <div class="texts">周骏飞</div>
          <img src="./imgs/usertel.png" alt="" title="联系电话" class="tel" />
          <div class="texts">18013886200</div>
        </div>
      </div>
      <div class="mocks" v-show="showdia" @click="closedlgs"></div>
      <!-- 背景2 -->
      <!-- 页面主体区域 -->
      <Header></Header>
      <Footer></Footer>
    </main>
  </div>
</template>

<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showdia: false,
    };
  },
  methods: {
    showdlg() {
      this.showdia = true;
    },
    closedel() {
      this.showdia = false;
    },
    closedlgs() {
      this.closedel();
    },
  },
};
</script>

<style lang="scss" scoped>
.dilog {
  z-index: 2;
  width: 544px;
  height: 320px;
  position: absolute;
  left: 700px;
  top: 374px;
  background: url("./imgs/dlog.webp") no-repeat center;
  background-size: 100% 100%;
  .imgtop {
    width: 148px;
    height: 120px;
    background: url("./imgs/hetop.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    right: -40px;
    top: -21px;
  }
  .imgleft {
    width: 156px;
    height: 118px;
    background: url("./imgs/heleft.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    left: -44px;
    bottom: -2px;
  }
  .textone {
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #856959;
    line-height: 24px;
    margin-top: 101px;
    margin-bottom: 14px;
  }
  .texttwo {
    font-size: 16px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #856959;
    letter-spacing: 2px;
  }
  .callwe {
    margin-top: 47px;
    padding-left: 85px;
    display: flex;
    position: absolute;
    .users {
      width: 24px;
      height: 24px;
    }
    .tel {
      width: 24px;
      height: 24px;

      margin-left: 120px;
    }
    .texts {
      margin-left: 20px;
      font-size: 18px;
      font-weight: 400;
      color: #856959;
    }
  }
  .close {
    width: 25px;
    height: 24px;
    background: url("./imgs/closeone.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    top: -35px;
    right: -24px;
    z-index: 5;
  }
  .close:hover {
    cursor: pointer;
  }
}
.mocks {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}
.bg1 {
  // min-width: 1920px;
  height: 600px;
  // background: url(./imgs/bg1.png);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
  .bg11 {
    height: 600px;
  }
  .gjx {
    min-width: 361px;
    height: 67px;
    font-size: 48px;

    font-weight: 500;
    color: #ffffff;
    line-height: 67px;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 229.9968px;
    left: 300px;
  }
  .hyzy {
    min-width: 360px;
    height: 33px;
    font-size: 24px;

    font-weight: 400;
    color: #ffffff;
    line-height: 33px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 321.0048px;
    left: 360px;
  }
  .ljgd {
    min-width: 168px;
    font-size: 20px;
    height: 48px;
    background: #007aff;
    border-radius: 24px;
    position: absolute;
    top: 394.0032px;
    left: 360px;
    color: #ffffff;
  }
}
.bg2 {
  // min-width: 1920px;
  height: 2069px;
  background: #ffffff;
  position: relative;
  .ykfw {
    min-width: 167px;
    height: 56px;
    font-size: 40px;

    font-weight: 400;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
    position: absolute;
    top: 80px;
    left: 875.0016px;
  }
  .flbt1 {
    min-width: 157px;
    height: 63px;
    background: #007aff;
    box-shadow: 0px 0px 8px 2px rgba(0, 122, 255, 0.48);
    border-radius: 4px;
    position: absolute;
    top: 176px;
    left: 360px;
    .font1 {
      min-width: 68px;
      height: 22px;
      font-size: 16px;

      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      position: absolute;
      top: 20px;
      left: 45px;
    }
  }
  .flbt2 {
    min-width: 157px;
    height: 63px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    position: absolute;
    top: 176px;
    left: 532px;
    .font2 {
      min-width: 68px;
      height: 22px;
      font-size: 16px;

      font-weight: 400;
      color: #666666;
      line-height: 22px;
      position: absolute;
      top: 20px;
      left: 45px;
    }
  }
  .flbt3 {
    min-width: 157px;
    height: 63px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    position: absolute;
    top: 176px;
    left: 707px;
    .font3 {
      min-width: 68px;
      height: 22px;
      font-size: 16px;

      font-weight: 400;
      color: #666666;
      line-height: 22px;
      position: absolute;
      top: 20px;
      left: 45px;
    }
  }
  .flbt4 {
    min-width: 157px;
    height: 63px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    position: absolute;
    top: 176px;
    left: 881px;
    .font4 {
      min-width: 68px;
      height: 22px;
      font-size: 16px;

      font-weight: 400;
      color: #666666;
      line-height: 22px;
      position: absolute;
      top: 20px;
      left: 45px;
    }
  }
  .flbt5 {
    min-width: 157px;
    height: 63px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    position: absolute;
    top: 176px;
    left: 1055px;
    .font5 {
      min-width: 68px;
      height: 22px;
      font-size: 16px;

      font-weight: 400;
      color: #666666;
      line-height: 22px;
      position: absolute;
      top: 20px;
      left: 45px;
    }
  }
  .flbt6 {
    min-width: 157px;
    height: 63px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    position: absolute;
    top: 176px;
    left: 1229px;
    .font6 {
      min-width: 68px;
      height: 22px;
      font-size: 16px;

      font-weight: 400;
      color: #666666;
      line-height: 22px;
      position: absolute;
      top: 20px;
      left: 45px;
    }
  }
  .flbt7 {
    min-width: 157px;
    height: 63px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    position: absolute;
    top: 176px;
    left: 1403px;
    .font7 {
      min-width: 68px;
      height: 22px;
      font-size: 16px;

      font-weight: 400;
      color: #666666;
      line-height: 22px;
      position: absolute;
      top: 20px;
      left: 45px;
    }
  }
  .icon1 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 269px;
    left: 361px;
    .image1 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .jqmp {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 99px;
      text-align: left;
    }
    .fh1 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon2 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 269px;
    left: 669px;
    .image2 {
      width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .zndh {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 99px;
    }
    .fh2 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon3 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 269px;
    left: 977px;
    .image3 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .tqzs {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 99px;
      text-align: left;
    }
    .fh3 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon4 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 269px;
    left: 1285px;
    .image4 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .cyfw {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 99px;
      text-align: left;
    }
    .fh4 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon5 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 615px;
    left: 360px;
    .image5 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .hdbm {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 99px;
      text-align: left;
    }
    .fh5 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon6 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 615px;
    left: 668px;
    .image6 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .zsfw {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 99px;
      text-align: left;
    }
    .fh6 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon7 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 615px;
    left: 976px;
    .image7 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .mzb {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 99px;
      text-align: left;
    }
    .fh7 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon8 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 615px;
    left: 1284px;
    .image8 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .fsyy {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 99px;
      text-align: left;
    }
    .fh8 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon9 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 961px;
    left: 360px;
    .image9 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .ds {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 120px;
      text-align: left;
    }
    .fh9 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon10 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 961px;
    left: 668px;
    .image10 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .pw {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 99px;
      text-align: left;
    }
    .fh10 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon11 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 961px;
    left: 976px;
    .image11 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .zbsc {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 88px;
      text-align: left;
    }
    .fh11 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon12 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 961px;
    left: 1284px;
    .image12 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .znsbhc {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 88px;
      text-align: left;
    }
    .fh12 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon13 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 1307px;
    left: 360px;
    .image13 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .znfy {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 88px;
      text-align: left;
    }
    .fh13 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon14 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 1307px;
    left: 669px;
    .image14 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .tsjpj {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 99px;
      text-align: left;
    }
    .fh14 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon15 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 1307px;
    left: 977px;
    .image15 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .jkm {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 99px;
      text-align: left;
    }
    .fh15 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon16 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 1307px;
    left: 1285px;
    .image16 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .swdt {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 99px;
      text-align: left;
    }
    .fh16 {
      width: 210px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: left;
    }
  }
  .icon17 {
    min-width: 278px;
    height: 316px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 1653px;
    left: 361px;
    .image17 {
      min-width: 76px;
      height: 76px;
      position: absolute;
      top: 48px;
      left: 102px;
    }
    .gdfw {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 148px;
      left: 68px;
      text-align: left;
    }
    .fh17 {
      width: 206px;
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 192px;
      left: 36px;
      text-align: center;
    }
  }
}
.bg3 {
  height: 243px;
  position: relative;
  .image2 {
    min-width: 359px;
    height: 47px;
    position: absolute;
    top: 100px;
    left: 711px;
  }
  .lhcp {
    width: 132px;
    height: 45px;
    font-size: 32px;
    color: #333333;
    position: absolute;
    top: 100px;
    left: 1081px;
  }
}
// 5 对话
/deep/.el-dialog {
  width: 35% !important;
  margin-top: 300px !important;
  .el-dialog__body {
    padding-top: 24px;
  }
  text-align: center;
  div {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
  .twotext {
    margin-top: 8px;
  }
}
</style>
